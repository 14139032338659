<template lang="pug">
Modal(@close="$emit('close')")
  template(#title) Редактирование ряда
  template(#)
    div.modal-form-input
      UiInput(
        label="Наименование ряда"
        v-model="currentData.name"
      )
  template(v-slot:controlButtons)
    .upload-modal__footer
      Btn(@click="$emit('close')") Отмена
      Btn(type="primary" size="large" @click="modalMarketState.submit") Сохранить
      //Btn(type="danger" size="large" @click="modalMarketState.delete") Удалить
</template>

<script>
import { defineComponent, reactive, watch } from 'vue';
// eslint-disable-next-line import/extensions
import Modal from '@/components/UI/Modal';
import api from '@/api';

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    data: {
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const currentData = reactive({});
    const modalMarketState = reactive({
      loading: false,
      async submit() {
        modalMarketState.loading = true;
        try {
          await api.markets.updatePassage(currentData);
          emit('close');
        } finally {
          modalMarketState.loading = false;
        }
      },
      // async delete() {
      //   modalMarketState.loading = true;
      //   try {
      //     await api.markets.deletePassage(currentData.id);
      //     emit('close');
      //   } finally {
      //     modalMarketState.loading = false;
      //   }
      // },
    });
    watch(() => props.data, () => {
      Object.assign(currentData, props.data);
    }, { deep: true, immediate: true });
    return {
      modalMarketState,
      currentData,
    };
  },
});
</script>

<style scoped>

</style>
